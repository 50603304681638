import { parseOneAddress, ParsedMailbox } from 'email-addresses'
import { useDebounceFn } from '@vueuse/core'

export const useWorkspace = () => {
  const authQuery = useFetchAuth()
  const { axios, onError } = useApi()
  const emailDomain = computed(() => {
    const user = authQuery.auth.value.user
    if (!user) {
      return
    }
    const { domain } = parseOneAddress(user.email) as ParsedMailbox
    return domain
  })
  const workspaceQuery = useQuery({
    queryFn: async () => {
      const workspaceId = authQuery.auth.value?.user?.workspace?.id
      if (!workspaceId) {
        return null
      }
      const response = await axios.get<ApiShow<Workspace>>(
        `workspaces/${workspaceId}`,
        {
          params: {
            relations: ['users', 'workspaceUsers', 'owners'],
            counts: [
              'patents',
              'grantPatents',
              'applicationPatents',
              'provisionalPatents'
            ]
          }
        }
      )
      return response.data.data
    },
    onError,
    queryKey: ['workspaces', authQuery.auth.value.user?.workspace?.id]
  })
  return { authQuery, emailDomain, workspaceQuery }
}

export const useWorkspaces = (params: globalThis.Ref) => {
  const { axios, onError } = useApi()
  const fetchWorkspaces = useDebounceFn(async () => {
    const { data } = await axios.get<ApiList<Workspace>>('workspaces', {
      params: params.value
    })
    return data
  }, 500)
  return useQuery({
    queryFn: async () => (await fetchWorkspaces()) ?? null,
    queryKey: ['workspaces', params],
    onError
  })
}
